import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonCol,
  IonGrid,
  IonDatetime,
  IonAlert,
} from "@ionic/react";
import {
  arrowUpCircleOutline,
  bookmarkOutline,
  calendarNumberOutline,
  personCircleOutline,
} from "ionicons/icons";
import React, { useState, useEffect } from "react";
import "./Home.css";
import { Formik } from "formik";
import * as yup from "yup";
import Moment from "moment";

const validationSchema = yup.object({
  vouchercode: yup.string().nullable().required("Voucher Code is required"),
  name: yup.string().nullable().required("Name is required"),
  bdate: yup.string().nullable().required("Birth Date is required"),
});

// interface interdata {
//   vouchercode: string;
//   name: string;
//   bdate: string;
// }

const Home: React.FC = () => {
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);

  const authResult = new URLSearchParams(window.location.search);
  const code = authResult.get("vouchercode");

  // console.log(code);
  const intialvalues = {
    vouchercode: code,
    name: null,
    bdate: null,
  };

  return (
    <IonPage className="ion-padding">
      <Formik
        initialValues={intialvalues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          fetch("https://link.spcmc.online/voucherapp/cinsert.php", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },

            // body: JSON.stringify(values, null, 2),
            body: JSON.stringify({
              vouchercode: values.vouchercode,
              name: values.name,
              bdate: Moment(new Date(values.bdate!)).format("YYYY-MM-DD"),
            }),
          })
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.PK_voucher > 0) {
                setShowAlert1(true);
                resetForm();
                setSubmitting(false);
              } else {
                setShowAlert2(true);
                resetForm();
                setSubmitting(false);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }}
      >
        {(formikProps) => (
          <IonContent>
            <IonGrid className="mainlayout">
              <IonCol className="maingrid">
                <IonRow>
                  <IonCol>
                    <img
                      src="assets/icon/voucher1.png"
                      alt="ion"
                      className="imgcontainer"
                    ></img>
                  </IonCol>
                </IonRow>

                <form onSubmit={formikProps.handleSubmit}>
                  <IonRow>
                    <IonCol>
                      <IonItem className="itemlistyle">
                        <IonIcon slot="start" icon={bookmarkOutline} />
                        <IonLabel position="stacked">Voucher Code</IonLabel>
                        <IonInput
                          type="text"
                          name="vouchercode"
                          value={formikProps.values.vouchercode}
                          onIonChange={formikProps.handleChange}
                        />
                        <p className="error">
                          {formikProps.touched.vouchercode &&
                            formikProps.errors.vouchercode}
                        </p>
                      </IonItem>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonItem className="itemlistyle">
                        <IonIcon slot="start" icon={personCircleOutline} />
                        <IonLabel color="dark" position="stacked">
                          Full Name
                        </IonLabel>
                        <IonInput
                          type="text"
                          autoCapitalize="none"                 
                          name="name"
                          value={formikProps.values.name}
                          onIonChange={formikProps.handleChange}                        
                        />
                        <p className="error">
                          {formikProps.touched.name && formikProps.errors.name}
                        </p>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem className="itemlistyle">
                        <IonIcon slot="start" icon={calendarNumberOutline} />
                        <IonLabel>Birth Day</IonLabel>

                        <IonDatetime
                          displayFormat="YYYY-MM-DD"
                          name="bdate"
                          value={formikProps.values.bdate}
                          doneText={"set"}
                          onIonChange={formikProps.handleChange}
                        />
                        <p className="error">
                          {formikProps.touched.bdate &&
                            formikProps.errors.bdate}
                        </p>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  {/* <CustomDateRange /> */}
                  <IonRow>
                    <IonCol>
                      <IonButton
                        className="itemlistyle"
                        shape="round"
                        type="submit"
                        color="success"
                        expand="block"
                      >
                        Register
                        <IonIcon slot="start" icon={arrowUpCircleOutline} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </form>

                <IonAlert
                  isOpen={showAlert1}
                  onDidDismiss={() => setShowAlert1(false)}
                  cssClass="my-custom-class"
                  header={"Confirmation"}
                  message={"You have Successfully Claim the Voucher!"}
                  buttons={[
                    {
                      text: "Ok",
                      handler: () => {},
                    },
                  ]}
                />

                <IonAlert
                  isOpen={showAlert2}
                  onDidDismiss={() => setShowAlert2(false)}
                  cssClass="my-custom-class"
                  header={"Confirmation"}
                  message={"Voucher Invalid or Claimed!."}
                  buttons={[
                    {
                      text: "Ok",
                      handler: () => {},
                    },
                  ]}
                />
              </IonCol>
            </IonGrid>
          </IonContent>
        )}
      </Formik>
    </IonPage>
  );
};

export default Home;
